.below-searchbar-recommendation {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
}

.below-searchbar-recommendation li {
  background-color: white;
  padding: 8px;
  cursor: pointer;
}

.transfer-method {
  font-size: 25px;
}

.input-container {
  justify-content: flex-start;
}

.input-container input {
  min-width: 400px !important;
}
.input-container div {
  padding: 0px 10px !important;
}

.pending-header {
  font-size: 130%;
  margin-right: 5px;
  color: #ff8084;
}

.delete-modal {
  top: 10;
  margin: auto;
  min-width: 140%;
}
@media (max-width: 600px) {
  .transfer-method {
    font-size: 15px !important;
  }

  .card-header {
    padding: 15px 3px !important;
  }

  .transfer-method-header {
    font-size: 13px !important;
  }

  .clock {
    font-size: 10px !important;
    margin-top: 5px !important;
  }

  .card-body {
    padding: 10px 0px !important;
  }

  .input-container {
    flex-direction: column !important;
    margin-top: 0px !important;
  }

  .input-container label {
    margin-top: 10px !important;
  }
  .input-container input {
    min-width: 300px !important;
  }
  .pending-header {
    font-size: 80% !important;
  }

  .media-body span {
    font-size: 15px !important;
  }

  .media-body h3 {
    font-size: 15px !important;
  }
  .delete-modal {
    min-width: 100%;
  }
}
